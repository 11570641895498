import React, { useEffect } from "react";
import { Fade } from "react-awesome-reveal";

// import sImg2 from '../../images/story/11.jpg'
// import sImg3 from '../../images/story/12.jpg'

// import shape1 from '../../images/story/s-shape1.png'
// import shape2 from '../../images/story/s-shape2.png'
import SectionTitle from "../SectionTitle";
import { connect } from "react-redux";
import moment from "moment";
import { Cloudinary_link } from "../../utils/constant";
import { getShortAddress } from "../../utils";
import coupleImg4 from "../../images/couple/right-invitee.svg";

const image =
  "https://res.cloudinary.com/dxedclcqu/image/upload/v1720252677/static-assets/toast_o7tsgn.svg";

const EventSection4 = ({ events = [], haveBackground = true }) => {
  return (
    <section className="wpo-story-section-s4 section-padding" id="story">
      <div className="container eventContainer section">
        <SectionTitle subTitle={"Our Wedding"} MainTitle={"When & Where"} />
        <div className="row">
          <div className="col col-xs-12">
            <div className="story-timeline">
              {haveBackground && (
                <div className="right-shape flower">
                  <img src={coupleImg4} alt="" />
                </div>
              )}
              <div className="round-shape"></div>
              {events.map((event, index) => {
                const isRight = index % 2 !== 0;
                const animate = 200 * (index + 1);
                return (
                  <div className="row">
                    {!isRight && <div className="col col-lg-6 col-12"> </div>}
                    <div
                      className={`col col-lg-6 col-12 text-holder ${
                        isRight ? "right-heart" : ""
                      }`}
                    >
                      <span className="heart">
                        <svg
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <path
                              d="M4.03553 1C1.80677 1 0 2.80677 0 5.03553C0 6.10582 0.42517 7.13228 1.18198 7.88909L7.14645 13.8536C7.34171 14.0488 7.65829 14.0488 7.85355 13.8536L13.818 7.88909C14.5748 7.13228 15 6.10582 15 5.03553C15 2.80677 13.1932 1 10.9645 1C9.89418 1 8.86772 1.42517 8.11091 2.18198L7.5 2.79289L6.88909 2.18198C6.13228 1.42517 5.10582 1 4.03553 1Z"
                              fill="#C8A898"
                            ></path>{" "}
                          </g>
                        </svg>{" "}
                      </span>
                      <Fade
                        delay={animate}
                        duration={animate * 5}
                        triggerOnce="true"
                      >
                        <div
                          className={`story-text  ${
                            isRight ? "right-align-text" : ""
                          }`}
                        >
                          <img
                            src={event.photo || image}
                            height={80}
                            alt=""
                            className="img img-responsive"
                            style={{ marginBottom: 20 }}
                          />
                          <h2 className="eventTitle">{event.name}</h2>
                          <span className="date">
                            {event?.dateAndTime?.date}{" "}
                            {event?.dateAndTime?.time} Onwards
                          </span>
                          <div
                            className={`event-row ${
                              isRight ? "right-align" : ""
                            }`}
                          >
                            <i className="fi flaticon-gallery"></i>
                            <span>Theme - {event.theme}</span>
                          </div>
                          <div
                            className={`event-row ${
                              isRight ? "right-align" : ""
                            }`}
                          >
                            <i className="fi flaticon-maps-and-flags"></i>
                            <span>{getShortAddress(event.venue)}</span>
                          </div>
                        </div>
                      </Fade>
                    </div>
                  </div>
                );
              })}
              <div className="row">
                <div className="col offset-lg-6 col-lg-6 col-12 text-holder">
                  <div className="round-shape-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    events: state.inviteDetails.invite?.events,
  };
};

export default connect(mapStateToProps)(EventSection4);
